export const labels = {
  product: "Produto",
  folder: "Categoria",
  newProductButton: "Novo Item",
  menuType: "menuDefault",
  logo: "/MeuBot-Dashboard-v3.png",
  createNew: true,
  botName: "Meus bots",
  botType: "Tipo",
  breadCrumbs: "Meus bots",
  parameterHeader: "Mensagem",
  botInteractions: "Interações com meus bots",
  showTutorials: true,
  eventCreationTitle: "Agendamento de serviço",
  eventCreationBread: "Agendamento de serviço",
  eventCreationSubTitle: "Dados do cliente",
  showSus: true,
  selectBot: "Escolha o bot",
  createButton: "Criar novo bot",
  loginButton: "Clique aqui para fazer login",
  notLogged1: "Você não está logado!",
  notLogged2: "para fazer login ou registrar-se clique no botão abaixo",
  loadingData: " Carregando dados ...",
}

export const homePage = {
  report: {
    botInteractions: "Integrações com meus bots",
    numberCustomers: "Número de Atendimentos",
    totalNumberCustomers: "Número total de clientes atendidos",
    msgReceived: "Mensagens Recebidas",
    totalNumberReceived: "Número total de mensagens recebidas por clientes",
    msgSente: "Mensagens Enviadas",
    totalNumberSent: "Número total de mensagens enviadas pelo chatbot",
  },
};

export const myBots = {
  createNew: "CRIAR NOVO BOT",
  createNewGpt: "CRIAR NOVO BOT COM GPT",
  loading: "Carregando dados...",
  sucess: "Sucesso!",
  tryIt: "Teste",
  config: "Configurações",
  emptyMsg: "Não encontramos nenhum bot existente, por favor clique no botão criar novo bot para começar",
  deleteConfirmation: "Tem certeza de que deseja desabilitar o bot? O número vai ser liberado do whatsapp imediatamente e após 45 dias todos os dados do robô seram apagados",
  deleteError: "Algo deu errado ao tentar excluir o bot",
  deleteSuccess: "Bot desabilitado!",
  menuAndProducs: "Menu & Produtos",
  connections: "Integrações",
  confirm: "Confirme",
  btnYes: "Sim",
  btnNo: "Não",

};

export const models = {
  success: "Sucesso!",
  errorTitle: "Erro!",
  infoAdded: "Informação adicionada",
  errorMsg: "Algo deu errado ao tentar salvar",
  save: "Salvar",
  menuAndProdSetting: "Menus & Produtos Configurações",
  search: "procurar",
  typeHere: "Digite aqui",
  noBookingFound: "Não foi possível encontrar o tipo de serviço",
  noStaffFound: "Sem profissionais cadastrados",
  deleteConfirmation: "Tem certeza de que deseja excluir o item:",
  name: "Nome",
  desc: "Descrição",
  price: "Preço",
  confirm: "Confirmar",
  btnSave: "Salvar",
  btnCancel: "Cancelar",
  btnYes: "Sim",
  btnNo: "Não",
  modelsCrumb: "Menus & Produtos",
  tableEmptyMsg: "Nenhum Registro encontrado!",
  requiredNameField: "Campo nome obrigatório",
};

export const myTeam = {
  active: "Ativado",
  inactive: "Desativado",
  searchStaff: "Procurar Profissionais",
  addStaff: "Cadastro de Profissionais",
  dataNotFound: "Nenhum dado encontrado",
  name: "Nome",
  email: "E-mail",
  whatsappNumber: "Número de Whatsapp",
  status: "Status",
  errorTitle: "Erro!",
  success: "Sucesso!",
  errorMsg: "Erro ao salvar profissional.",
  btnNew: "Novo",
  btnSave: "Salvar",
  btnCancel: "Cancelar",
  search: "Procurar...",
  employeeName: "Nome do Profissional",
  nickName: "Apelido",
  commercialPhone: "Telefone comercial",
  activeInactive: "Ativado/Desativado",
  contractingType: "Tipo de contratação",
  selectContractingType: "Selecione o tipo de contratação",
  bookingTypes: "Tipos de Agendamentos",
  selectBookingTypes: "Selecione o Tipo de Agendamento",
  weekTimeTitle: "Personalizar Horários",
};

export const myServices = {
  active: "Ativado",
  inactive: "Desativado",
  searchService: "Procurar Tipos de Agendamento",
  bookingType: "Tipos de Agendamento",
  duration: "Duração",
  search: "Procurar...",
  new: "Novo",
  errorTitle: "Erro!",
  errorEmployee: "Erro ao salvar profissional.",
  btnNew: "New",
  btnSave: "Salvar",
  btnCancel: "Cancelar",
  currentPageReportTemplate: "Mostrando {first} até {last} do total {totalRecords} de Tipos de Agendamentos",
  emptyTable: "Nenhum dado foi encontrado!",
  columDuration: "Duração",
  activeInactive: "Ativado/Desativado",
  meetingTitle: "Titulo da Reunião",
  meetingDescription: "Descrição da Reunião",
  qualifiedEmployees: "Funcionários habilitados para este serviço",
  selectEmployee: "Selecione os funcionários",
  localization: "Localização",
  createLink: "Criar Link de Reunião no Meeting",
};

export const createBotClient = {
  botSuccess: "Bot criado com sucesso!",
  botCustomSuccess: "Bot configurado com sucesso",
  success: "Successo!",
  errorMessage: "Algo deu errado",
  errorTitle: "Erro",
  createBot: "Criar novo bot",
  cancel: "Cancelar",
  botMaxAlert: "Você atingiu a quantidade máxima de bots permitidos",
  features: "Funcionalidades",
  chooseTemplate: "Escolha abaixo o modelo que mais se adequa ao seu negócio",
  emptyTemplate: "Nenhum modelo personalizado encontrado",
  waitingMsg: "Aguarde um minuto, estamos criando seu bot...",
  testMsg: "Clique aqui para testar",
  integrationMsg: "Integrar com Whatsapp",
  chooseName: "Escolha o nome do seu bot",
  nameBot: "Nome do bot"
};

export const createBotLifty = {
  botSuccess: "Bot criado com sucesso!",
  botCustomSuccess: "Bot configurado com sucesso",
  success: "Successo!",
  errorMessage: "Algo deu errado",
  errorTitle: "Erro",
  createBot: "Criar novo bot",
  cancel: "Cancelar",
  botMaxAlert: "Você atingiu a quantidade máxima de bots permitidos",
  botCreation: "Criação de bots",
  nameBot: "Nome do bot",
  chooseTemplate: "Escolha o template:",
  createBotNow: "Criar meu bot agora",
  waitingMsg: "Aguarde um minuto, estamos criando seu bot...",
  testMsg: "Clique aqui para testar",
  integrationMsg: "Integrar com Whatsapp",
};

export const eventsCreation = {
  invalidDate: "Data inválida, selecione uma data válida e tente novamente",
  successMsg: "Serviço agendado!",
  errorMsg: "Algo deu errado, verifique seus dados e tente novamente",
  selectStaff: "Selecione o profissional:",
  bookingType: "Selecione o tipo de agendamento:",
  selectStaffMsg: "Primeiro escolha o profissional",
  bookingDate: "Data do agendamento:",
  bookingTime: "Horário do agendamento",
  fullName: "Nome completo",
  birthDate: "Data de nascimento",
  whatsappNumber: "Número de Whatsapp",
  address: "Endereço"
};

export const integration = {
  awaitMessage: "Conexão com Whatsapp está sendo realizada aguarde um pouco.",
  awaitTitle: "Aguardar",
  statusError: "Erro ao buscar Status do Whatsapp!.",
  desireSuccess: "Seu interesse foi registrado com sucesso!",
  desireError: "Ocorreu um erro na hora de registrar o seu interesse.",
  awaitDetail: "Interrompendo conexão com Whatsapp",
  errorTitle: "Erro!",
  errorDetail: "Erro ao tentar interromper integração com Whatsapp!",
  startWhatsapp: "Iniciando integração com Whatsapp!",
  startError: "Erro ao tentar restabelecer integração com Whatsapp!",
  pageTitle: "Integrações",
  freeWaText: "Integração com Whatsapp Business",
  freeWaTitle: "Whatsapp Business",
  connected: "Conexão ativada",
  activateConnection: "Ativar Conexão",
  cloudApiTitle: "Whatsapp Cloud Api",
  cloudApiText: "Integração com Whatsapp Cloud Api",
  chatGPTTitle: "ChatGPT API",
  chatGPTText: "Integração com Chat GPT",
  websiteText: "Integração com Website",
  websiteButton: "Mostrar Detalhes",
  websiteLink: "O link abaixo permite que voce adicione o seu Chatbot em qualquer website, o link tambem pode ser compartilhado no perfil do instagram, entre outros. <br /><br /><b></b>Link do seu WebChat Bot: <br />",
  desireButton: "Registrar interesse",
  facebookText: "Integração com o Facebook Messenger",
  instagramText: "Integração com o Instagram Messenger",
  telegramText: "Integração com o App do Telegram",
  slackText: "Integração com o Slack",
  wordpressText: "Integração com WordPress",
}

export const myProfile = {
  success: "Successo!",
  successText: "Perfil de Usuário salvo com sucesso!",
  error: "Erro!",
  errorText: "Ocorreu um erro ao salvar o Perfil de Usuário!",
  passwordSuccess: "Senha alterada com sucesso! click ok para retornar a pagina inicial",
  passwordError: "Ocorreu um erro tentar alterar a senha",
  passwordMatchError: "As senhas não conferem, tente novamente.",
  passwordContent: "Sunha senha deve conter:",
  lettersLength: "Pelo menos 8 caracteres de extensão",
  lowercase: "Letras minúsculas (a-z)",
  uppercase: "Letras maiúsculas (A-Z)",
  numbers: "Números (0-9)",
  pageTitle: "Perfil de Usuário",
  businessName: "Razão Social",
  documentNumber: "CNPJ",
  address: "Endereço",
  district: "Bairro",
  city: "Cidade",
  state: "Estado",
  zipCode: "CEP",
  btnSave: "Salvar",
  btnChangePW: "Alterar senha",
  newPasswordText: "Digite a nova senha",
  newPasswordText: "Confirme a nova senha",
  logged: "Você está logado como:",
  btnCancel: "Cancelar",
}

export const reminders = {
  success: "Successo!",
  successText: "Lembrete salvo com sucesso!",
  error: "Erro!",
  errorText: "Ocorreu um erro ao salvar o lembrete!",
  pageTitle: "Lembrete",
  chooseBot: "Escolha seu bot",
  timeSlider: "Tempo de antecedência (Horas)",
  reminderMsg: "Mensagem do lembrete",
  btnSave: "Salvar",
  active: "Ativado",
  inactive: "Desativado",
}

export const userDataLabels = {
  success: "Successo!",
  successText: "Dados de usuário salvo com sucesso!",
  successText: "Dados de usuário deletado!",
  error: "Erro!",
  errorText: "Ocorreu um erro ao salvar os dados de usuário!",
  dataUpdate: "Dado atualizado",
  dataCreate: "Dado criado",
  btnDelete: "Deletar",
  btnSave: "Salvar",
  searchData: "Procurar Dados",
  searchData: "Procurar...",
  btnCancel: "Cancelar",
  btnYes: "Sim",
  btnNo: "Não",
  pageTitle: "Dados de Usuário",
  fieldId: "ID do usuário",
  channel: "Canal",
  dataType: "Tipo de dado",
  data: "Dados",
  confirmDelete: "Tem certeza de que deseja excluir",
  confirmDeleteSelected: "Tem certeza de que deseja excluir os Dados de Usuários selecionados?",
}

export const weektimes = {
  weekDay1: "Segunda-Feira",
  weekDay2: "Terça-Feira",
  weekDay3: "Quarta-Feira",
  weekDay4: "Quinta-Feira",
  weekDay5: "Sexta-Feira",
  weekDay6: "Sábado",
  weekDay7: "Domingo",
  startTime: "Horário inicial",
  endTime: "Horário final"
}

export const whatsActivation = {
  pageTitle: "Whatsapp Business",
  openWhats: "Abra o Whatsapp Business no aparelho com o numero que voce deseja colocar o Bot, clique em 'Aparelhos conectados'",
  note: "<b>Obs:</b>Lembre-se de usar um número exclusivo para o bot, evitando assim que o bot responda as suas mensagens pessoais.",
  terms1: "Declaro que estou usando o aplicativo Whatsapp Business",
  terms2: "Declaro que li e estou ciente das políticas do",
  showQr: "Mostrar QRCODE",
}

export const cloudActivation = {
  success: "Successo!",
  successText: "Dados salvos com sucesso!",
  error: "Erro!",
  errorText: "Ocorreu um erro ao salvar os dados",
  interestError: "Erro ao enviar email de interesse!",
  terms1: "Declaro que estou ciente da contratação antecipada da Cloud API.",
  terms2: "Declaro que estou ciente das tarifas de cobrança do whatsapp cloud API",
  btnSave: "Salvar",
  btnCancel: "Cancelar",
}

export const chatGPT = {
  success: "Successo! ChatGPT ativado!",
  successText: "Dados salvos com sucesso!",
  error: "Erro: Verifique o token!",
  errorText: "Ocorreu um erro ao salvar os dados",
  btnSave: "Salvar",
  btnCancel: "Cancelar",
}

export const configs = {
  basic: "Básico",
  advanced: "Avançado",
  success: "Successo!",
  successText: "Configurações salva com sucesso!",
  btnSave: "Salvar",
  msgCrumb: "Mensagem",
  fieldName: "Nome",
  fieldDescription: "Descrição",
}

export const marketing = {
  errorProcess: "Erro no processamento da Campanha!",
  gotoIntegrations: "Ir para integrações",
  chooseAnother: "Escolher outro bot",
  success: "Successo!",
  successText: "Dados salvos com sucesso!",
  error: "Erro!",
  errorText: "Ocorreu um erro ao salvar os dados",
  msgUpdate: "Mensagem atualizada com sucesso!",
  msgSuccess: "Mensagem salva com sucesso!",
  msgError: "Erro ao salvar mensagem!.",
  btnSave: "Salvar",
  btnCancel: "Cancelar",
  errorStatusWpp: "Erro ao buscar Status do Whatsapp!",
  emptyIntegration: "Seu bot não esta integrado com nenhum Whatsapp, para fazer o desparo da campanha é necessario fazer a integração",
  errorFindMsg: "Erro ao buscar Mensagens!",
  msgDeleted: "Mensagem excluida com sucesso!",
  msgErrorDeleted: "Erro ao excluir mensagem!",
  spaceError: "O nome da planilha não deve conter espaços",
  fileUpload: "Arquivo enviado com sucesso!",
  yes: "Sim",
  no: "Não",
  btnNew: "Novo",
  chooseSpread: "Escolha planilha",
  chooseImage: "Escolha Imagem",
  sending: "Enviando...",
  imageHere: "Arraste e solte a imagem aqui.",
  pageTitle: "Campanhas",
  successCampaign: "Campanha criada com sucesso!",
  msgCampaign: "Mensagem da campanha",
  msgCreate: "Nenhuma mensagem encontrada, crie uma agora mesmo!",
  msgContent: "Conteúdo da Mensagem",
  title: "Titulo da mensagem",
  aproved: "Aprovado",
  msgEdit: "Editar mensagens da campanha",
  msgCreation: "Criar Mensagens da Campanha",
  chooseBot: "Escolha um bot",
  spreadHere: "Arraste e solte a planilha aqui.",
  download: "Clique aqui para baixar uma planilha de exemplo",
  shoot: "Data do disparo",
  time: "Horário do disparo",
  leadsInterval: "Intervalo entre leads",
  phoneColumn: "Coluna N Telefone",
  shootCampaign: "Disparar campanha a partir do fluxo.",
  msgChoose: "Escolha uma mensagem",
  createMsg: "Criar mensagem",
  integrationIssues: "Problemas na integração com Whatsapp"
}
export const topNavBar = {
  profile: "Perfil",
  welcome: "bem vindo(a) a plataforma da Lifty!",
  logout: "Sair",
}