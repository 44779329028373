export default class MybotsIntegrations {

    getBots(tenantId, accessToken) {
        return fetch(`${process.env.REACT_APP_DASHBOARD_GATEWAY}/bot/my/${tenantId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                mode: 'no-cors'
            }
        })
            .then((res) => res.json())
            .then((data) => data)
            .catch((error) => {
                console.log(error);
                window.alert("Erro ao obter minha lista de bots.");
            });
    }

    disableBot(botId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/bot/disable/${botId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => {
            if (response.status === 200 || response.status === 204) {
                return {
                    success: true
                };
            } else {
                return {
                    success: false
                };
            }
        })
            .catch(error => {
                throw error;
            });
    }
}