import React, { useState, useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
import WhatsappActivation from "../components/WhatsappActivation";
import ChatGPTActivation from "../components/ChatGPTActivation";
import WhatsappIntegration from "../components/integration/WhatsappIntegration";
import { Link, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import VotingIntegration from "../components/integration/VotingIntegration";
import { useAuth0 } from "@auth0/auth0-react";
import { Toast } from "primereact/toast";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
//Import Pictures
import IntegrationWhatsapp from "../../src/img/IntegrationWhatsapp.jpg";
import IntegrationFacebok from "../../src/img/IntegrationFacebok.jpg";
import IntegrationInstagram from "../../src/img/IntegrationInstagram.jpg";
import IntegrationChatGPT from "../../src/img/IntegrationChatGPT.jpg";
import IntegrationSlack from "../../src/img/IntegrationSlack.jpg";
import IntegrationTelegram from "../../src/img/IntegrationTelegram.jpg";
import IntegrationWP from "../../src/img/IntegrationWP.jpg";
import IntegrationWeb from "../../src/img/IntegrationWebsite.jpg";
import IntegrationCloudApi from "../../src/img/IntegrationCloudApi.jpg";
import { labels, chatGPT, integration } from "../labels/labels.js";

//Modal Styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const styleCloud = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 470,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

//Get BotId and BotName from URL Params
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Integrations() {
  const query = useQuery();
  let botId = query.get("botId"); //where botIs is the parameterName into URL
  let botName = query.get("botName");
  const toast = useRef(null);
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPlay, setShowPlay] = useState(true);
  const [showPause, setShowPause] = useState(false);
  const [statusURL, setStatusURL] = useState("");
  const timer = React.useRef();

  const [openWhatsapp, setOpenWhatsapp] = useState(false);
  const handleOpenWhatsapp = () => setOpenWhatsapp(true);
  const handleCloseWhatsapp = () => setOpenWhatsapp(false);

  const [openChatGPT, setCloudGPT] = useState(false);
  const handleOpenChatGPT = () => setCloudGPT(true);
  const handleCloseChatGPT = () => setCloudGPT(false);

  const [openWeb, setOpenWeb] = useState(false);
  const handleOpenWeb = () => setOpenWeb(true);
  const handleCloseWeb = () => setOpenWeb(false);

  const whatsIntegration = new WhatsappIntegration();
  const votingIntegration = new VotingIntegration();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }
  const chatGPTSucceed = () => {
    handleCloseChatGPT();
    toast.current.show({
      severity: "success",
      summary: chatGPT.success,
      detail: chatGPT.successText,
      life: 5000,
    });
  };
  const props = {
    botId,
    user,
    setConnected,
    handleCloseWhatsapp,
    handleCloseChatGPT,
    chatGPTSucceed,
    setShowPlay,
    setShowPause,
  };

  //load whatsapp integration status
  useEffect(() => {
    getAccessToken().then((accessToken) => {
      whatsIntegration.getWhatsappStatusURL(botId, accessToken).then((whatsappSessionStatusURL) => {
        setStatusURL(whatsappSessionStatusURL);
        const cloudApi = whatsappSessionStatusURL.includes("graph.facebook.com");
        if (cloudApi) {
          //since it returned cloud API, means no web connection is active
          console.log("Using cloud API, no whatsapp web connection found");
          setShowPlay(false);
          setShowPause(false);
          setLoading(false);
          setConnected(false);
        } else {
          updateWhatsappStatus(whatsappSessionStatusURL);
        }
      });
    });
  }, []);

  const updateWhatsappStatus = (statusURL) => {
    clearTimeout(timer.current);
    whatsIntegration.getSessionStatus(statusURL).then((response) => {
      setLoading(false);
      switch (response) {
        case "CONNECTED":
          setConnected(true);
          setShowPlay(false);
          setShowPause(true);
          break;
        case "QRCODE":
          setConnected(false);
          setShowPlay(false);
          setShowPause(false);
          break;
        case "STARTING":
          toast.current.show({
            severity: "warn",
            summary: integration.awaitTitle,
            detail: integration.awaitMessage,
            life: 5000,
          });
          timer.current = window.setTimeout(() => {
            updateWhatsappStatus(statusURL);
          }, 3000);
          break;
        case "CLOSED":
          setShowPlay(true);
          setShowPause(false);
          break;
        case "NOT_FOUND":
          setShowPlay(false);
          setShowPause(false);
          break;
        case "UNLAUNCHED":
          setShowPlay(false);
          setShowPause(false);
          break;
        case "ERROR":
          toast.current.show({
            severity: "error",
            summary: "Erro!",
            detail: integration.statusError,
            life: 5000,
          });
          setShowPlay(false);
          setShowPause(false);
          break;
        default:
          setShowPlay(true);
      }
    });
  };

  const showResponseMessage = (json) => {
    //show message
    if ((json.status = 200)) {
      toast.current.show({
        severity: "showPlay",
        summary: "Sucesso!",
        detail: integration.desireSuccess,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Erro!",
        detail: "ERRO.",
        life: 5000,
      });
    }
  };

  const handleButtonClick = () => {
    if (!loading) {
      if (showPlay) {
        StartListener();
      } else {
        StopListener();
      }
    }
  };
  // STOP THE CONNECTION WITH WHATSAPP
  const StopListener = () => {
    setLoading(true);
    getAccessToken().then((accessToken) => {
      whatsIntegration.stopListener(botId, accessToken).then((json) => {
        if (json.statusURL) {
          timer.current = window.setTimeout(() => {
            updateWhatsappStatus(json.statusURL);
            toast.current.show({
              severity: "success",
              summary: integration.awaitTitle,
              detail: integration.awaitDetail,
              life: 5000,
            });
          }, 3000);
        } else {
          setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "Erro!",
            detail: integration.stopError,
            life: 5000,
          });
        }
      });
    });
  };

  // START THE CONNECTION WITH WHATSAPP IF QRCODE IS RUNNING
  const StartListener = () => {
    setLoading(true);
    getAccessToken().then((accessToken) => {
      whatsIntegration.startSession(botId, accessToken).then((json) => {
        if (json.statusURL) {
          timer.current = window.setTimeout(() => {
            updateWhatsappStatus(json.statusURL);
            toast.current.show({
              severity: "success",
              summary: integration.awaitTitle,
              detail: integration.startWhatsapp,
              life: 5000,
            });
          }, 3000);
        } else {
          setLoading(false);
          toast.current.show({
            severity: "error",
            summary: integration.errorTitle,
            detail: integration.startError,
            life: 5000,
          });
        }
      });
    });
  };

  //Voting Integration (email api)

  const votingHandler = (integrationName) => {
    votingIntegration
      .sendVotingEmail(botId, integrationName, user)
      .then((json) => {
        showResponseMessage(json);
      })
      .catch((error) => {
        console.log("catch", error);
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: integration.desireError,
          life: 5000,
        });
      });
  };

  const votingInstagram = () => {
    votingHandler("Instagram");
  };
  const votingTelegram = () => {
    votingHandler("Telegram");
  };
  const votingFacebook = () => {
    votingHandler("Facebook");
  };
  const votingSlack = () => {
    votingHandler("Slack");
  };

  const votingWP = () => {
    votingHandler("Wordpress");
  };
  const buttonSx = {
    ...(showPlay && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  }

  return (
    isAuthenticated && (
      <div className="integrations">
        <Toast ref={toast} />
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/MyBots">{labels.breadCrumbs}</Link>
            </li>
            <li className="breadcrumb-item active">
              <a href="#">{integration.pageTitle}</a>
            </li>
          </ol>
        </nav>
        <Typography variant="h4" gutterBottom component="div" sx={{ p: 1, mb: 3 }}>
          {integration.pageTitle + " | " + botName}
        </Typography>

        <Grid container spacing={4} sx={{ ml: 2 }}>
          <Grid item>
            <Card sx={{ maxWidth: 345, height: 279.17 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationCloudApi} alt="Whatsapp Cloud API" />
              <CardContent sx={{ pb: 0 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {integration.cloudApiTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.cloudApiText}
                </Typography>
              </CardContent>

              <CardActions disableSpacing sx={{ pt: 0 }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => window.open(`${process.env.REACT_APP_WHATSAPP_INTEGRATION}?botId=${botId}`, "_blank")}
                  sx={{ p: 1 }}
                >
                  {integration.activateConnection}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345, height: 279.17 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationChatGPT} alt="ChatGPT Integration" />
              <CardContent sx={{ pb: 0 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {integration.chatGPTTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.chatGPTTitle}
                </Typography>
              </CardContent>

              <CardActions disableSpacing sx={{ pt: 0 }}>
                <Button size="small" color="primary" onClick={handleOpenChatGPT} sx={{ p: 1 }}>
                  {integration.activateConnection}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationWeb} alt="Web Integration" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Website
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.websiteText}
                </Typography>
              </CardContent>

              <CardActions>
                <Button size="small" color="primary" onClick={handleOpenWeb}>
                  {integration.websiteButton}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationFacebok} alt="Facebook Integration" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Facebook Messenger
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.facebookText}
                </Typography>
              </CardContent>

              <CardActions>
                <Button size="small" color="primary" onClick={votingFacebook}>
                  {integration.desireButton}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationInstagram} alt="Instagram Integration" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Instagram Messenger
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.instagramText}
                </Typography>
              </CardContent>

              <CardActions>
                <Button size="small" color="primary" onClick={votingInstagram}>
                  {integration.desireButton}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationTelegram} alt="Telegram Integration" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Telegram
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.telegramText}
                </Typography>
              </CardContent>

              <CardActions>
                <Button size="small" color="primary" onClick={votingTelegram}>
                  {integration.desireButton}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationSlack} alt="Slack Integration" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Slack
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.slackText}
                </Typography>
              </CardContent>

              <CardActions>
                <Button size="small" color="primary" onClick={votingSlack}>
                  {integration.desireButton}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ maxWidth: 345 }} elevation={5}>
              <CardMedia component="img" height="140" image={IntegrationWP} alt="Web Integration" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Wordpress
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {integration.wordpressText}
                </Typography>
              </CardContent>

              <CardActions>
                <Button size="small" color="primary" onClick={votingWP}>
                  {integration.desireButton}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        {/* Modals  */}

        <div className="modal-div">
          <Modal
            open={openWhatsapp}
            onClose={handleCloseWhatsapp}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <WhatsappActivation {...props} />
            </Box>
          </Modal>

          <Modal
            open={openChatGPT}
            onClose={handleCloseChatGPT}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleCloud}>
              <ChatGPTActivation {...props} />
            </Box>
          </Modal>

          <Modal
            open={openWeb}
            onClose={handleCloseWeb}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {integration.websiteText}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {integration.websiteLink}
                <Box
                  sx={{
                    bgcolor: "text.disabled",
                    color: "background.paper",
                    p: 2,
                  }}
                >
                  <code>{`${process.env.REACT_APP_CHAT_URL}?botId=${botId}`}</code>
                </Box>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    )
  );
}

export default Integrations;
