import React, { useState, useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Slider } from "primereact/slider";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ToggleButton } from "primereact/togglebutton";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Checkbox } from "primereact/checkbox";
import { Skeleton } from "primereact/skeleton";

import { Link, useNavigate } from "react-router-dom";

import useGlobalStyles from "../components/css/GlobalStyles";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { useAuth0 } from "@auth0/auth0-react";

import "primeflex/primeflex.css";
import "../components/css/Campaign.css";
import CampaignIntegration from "../components/integration/CampaignIntegration";
import MybotsIntegrations from "../components/integration/MybotsIntegrations";
import WhatsappIntegration from "../components/integration/WhatsappIntegration";
import LoginAlert from "./LoginAlert";
import { ExcelColumns } from "../utils/constants";

import { labels, marketing } from "../labels/labels.js";

let initialCampaign = {
  date: new Date(),
  interval: 1,
  message: null,
  time: new Date(),
  image: [],
  fileName: "",
  botId: "",
  column: {
    name: "A",
    code: "0",
  },
};

let emptyCampaignMessage = {
  botId: "",
  title: "",
  message: "",
  validated: false,
};

const Campaign = () => {
  const classes = useGlobalStyles();
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [inactivated, setInactivated] = useState(true);
  const toast = useRef(null);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [updateMessageDialog, setUpdateMessageDialog] = useState(false);
  const [newMessageDialog, setNewMessageDialog] = useState(false);
  const [campaignMessagesDialog, setCampaignMessagesDialog] = useState(false);
  const [validatedMessages, setValidatedMessages] = useState();
  const [campaignMessages, setCampaignMessages] = useState();
  const [campaignMessageNew, setCampaignMessageNew] = useState(emptyCampaignMessage);
  const [campaignMessageUpdate, setCampaignMessageUpdate] = useState(emptyCampaignMessage);
  const [loading, setLoading] = useState(true);
  const [imageTxt, setImageTxt] = useState("");
  const [detailMessage, setDetailMessage] = useState("");
  const [campaign, setCampaign] = useState(initialCampaign);
  const [interval, setInterval] = useState(1);
  const [bots, setBots] = useState([]);
  const [selectedBots, setSelectedBots] = useState([]);
  const [validatingBot, setValidatingBot] = useState(false);
  const [useCloudApi, setUseCloudApi] = useState(false);
  const [previewFile, setPreviewFile] = useState("");
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [useWelcomeIntent, setUseWelcomeIntent] = useState(false);
  const [integrationDialog, setIntegrationDialog] = useState(false);
  const [messageIntegration, setMessageIntegration] = useState("");
  const dt = useRef(null);
  const campaignIntegration = new CampaignIntegration();
  const mybotsIntegrations = new MybotsIntegrations();
  const whatsIntegration = new WhatsappIntegration();

  let column = ExcelColumns;

  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }

  const loadInitialData = () => {
    if (shouldLoadData) {
      setShouldLoadData(false);
      getAccessToken().then((accessToken) => {
        const tenantId = user.email;
        mybotsIntegrations.getBots(tenantId, accessToken).then((data) => {
          if (data) {
            let localBots = [];
            for (let i = 0, l = data.length; i < l; i += 1) {
              if (data[i]) {
                let bot = {
                  name: data[i].botName,
                  code: data[i].id,
                };
                localBots.push(bot);
              }
            }
            setBots(localBots);
          }
        });
      });
    }
  };

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {labels.loadingData}
      </div>
    );
  } else if (isAuthenticated) {
    //load bots after authentication
    loadInitialData(bots);
  }

  const showResponseMessage = (json) => {
    if (json.success) {
      //toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: json.mensagem, life: 5000 });
      setDetailMessage(json.message);
      setShowMessageDialog(true);
      setInactivated(true);
    } else {
      toast.current.show({
        severity: "error",
        summary: marketing.errorProcess,
        detail: json.message,
        life: 5000,
      });
      //check file name exists
      if (!campaign.fileName) {
        setInactivated(true);
      } else {
        setInactivated(false);
      }
    }
  };
  const hideIntegrationModelDialog = () => {
    setSelectedBots([]);
    setIntegrationDialog(false);
  };

  const integrationModelDialogFooter = (
    <React.Fragment>
      <Button
        label={marketing.gotoIntegrations}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => navigate("/Integrations?botId=" + selectedBots.code + "&botName=" + selectedBots.name)}
      />
      <Button
        label={marketing.chooseAnother}
        icon="pi pi-check"
        className="p-button-text"
        onClick={hideIntegrationModelDialog}
      />
    </React.Fragment>
  );

  const onSubmit = (e) => {
    e.preventDefault();
    //parsing date and time options
    let newDateOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    let newTimeOptions = {
      hour: "numeric",
      minute: "numeric",
    };
    //payload creation
    let templateCampaign = {
      fileName: campaign.fileName,
      date: campaign.date.toLocaleString("pt-BR", newDateOptions),
      time: campaign.time.toLocaleString("pt-BR", newTimeOptions),
      interval: interval,
      message: useWelcomeIntent ? "#WELCOME" : campaign.message.message.replace(/(\r\n|\n|\r)/gm, "<BR>") + imageTxt,
      botId: selectedBots.code,
      phoneColumn: campaign.column.code,
      cloudAPI: useCloudApi,
    };

    campaignIntegration
      .saveCampaign(templateCampaign)
      .then((json) => {
        showResponseMessage(json);
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: marketing.error,
          detail: marketing.errorText,
          life: 5000,
        });
      });
  };
  const onHide = (field) => {
    if (field === "showMessageDialog") {
      setShowMessageDialog(false);
    } else if (field === "campaignMessagesDialog") {
      setCampaignMessagesDialog(false);
    } else if (field === "updateMessageDialog") {
      setUpdateMessageDialog(false);
    } else if (field === "newMessageDialog") {
      setNewMessageDialog(false);
    }
  };
  const updateCampaignMessage = () => {
    setLoading(true);
    campaignMessageUpdate.validated = false;
    campaignIntegration
      .updateCampaignMessage(campaignMessageUpdate, selectedBots.code)
      .then((json) => {
        toast.current.show({
          severity: "success",
          summary: marketing.success,
          detail: marketing.msgUpdate,
          life: 5000,
        });
        setUpdateMessageDialog(false);
        getMessages(json.botId);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: marketing.error,
          detail: marketing.errorText,
          life: 5000,
        });
      });
  };
  const newCampaignMessage = () => {
    setLoading(true);
    campaignMessageNew.validated = false;
    campaignIntegration
      .saveCampaignMessage(campaignMessageNew, selectedBots.code)
      .then((json) => {
        toast.current.show({
          severity: "success",
          summary: marketing.success,
          detail: marketing.msgSuccess,
          life: 5000,
        });
        setNewMessageDialog(false);
        getMessages(json.botId);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: marketing.error,
          detail: marketing.msgError,
          life: 5000,
        });
      });
  };
  const UpdateDialogFooter = (
    <React.Fragment>
      <Button
        label={marketing.btnCancel}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => onHide("updateMessageDialog")}
      />

      <Button
        label={marketing.btnSave}
        icon="pi pi-check"
        className="p-button-text"
        onClick={updateCampaignMessage}
        loading={loading}
      />
    </React.Fragment>
  );
  const NewDialogFooter = (
    <React.Fragment>
      <Button
        label={marketing.btnCancel}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => onHide("newMessageDialog")}
      />

      <Button
        label={marketing.btnSave}
        icon="pi pi-check"
        className="p-button-text"
        onClick={newCampaignMessage}
        loading={loading}
      />
    </React.Fragment>
  );
  const dialogFooter = (field) => {
    return (
      <div className="p-d-flex p-jc-center">
        <Button label="OK" className="p-button-text" autoFocus onClick={() => onHide(field)} />
      </div>
    );
  };
  const processBotSelection = async (bot) => {
    getMessages(bot.code);
    setValidatingBot(true);
    const statusURL = await getWhatsappUrl(bot);
    console.log("🚀 ~ processBotSelection ~ statusURL:", statusURL)
    const cloudApi = statusURL.includes("graph.facebook.com");
    setUseCloudApi(cloudApi);
    //quick fix for oficial cloud api campaigns
    setValidatingBot(false);
    if (cloudApi) {
    } 
    // else {
    //   whatsIntegration.getSessionStatus(statusURL).then((response) => {
    //     setValidatingBot(false);
    //     switch (response) {
    //       case "CONNECTED":
    //         break;
    //       case "ERROR":
    //         console.log("ERROR:", response);
    //         toast.current.show({
    //           severity: "error",
    //           summary: marketing.error,
    //           detail: marketing.errorStatusWpp,
    //           life: 5000,
    //         });
    //         setSelectedBots([]);
    //         break;
    //       default:
    //         setIntegrationDialog(true);
    //         setMessageIntegration(marketing.emptyIntegration);
    //         break;
    //     }
    //   });
    // }
  };

  const getWhatsappUrl = async (bot) => {
    const accessToken = await getAccessToken();
    const response = await whatsIntegration.getWhatsappStatusURL(bot.code, accessToken);
    return response;
  };

  const getMessages = (botId) => {
    campaignIntegration
      .getCampaignMessages(botId)
      .then((data) => {
        setCampaignMessages(data.messages);
        setLoading(false);
        setValidatedMessages(data.messages.filter((message) => message.validated));
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: marketing.error,
          detail: marketing.errorFindMsg,
          life: 5000,
        });
      });
  };

  const CampaignMessagesVisible = () => {
    setCampaignMessagesDialog(true);
  };

  const newMessage = () => {
    setCampaignMessageNew(emptyCampaignMessage);
    setNewMessageDialog(true);
  };

  const updateMessage = (rowData) => {
    setCampaignMessageUpdate(rowData);
    setUpdateMessageDialog(true);
  };
  const onInputNewChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _campaignMessageNew = { ...campaignMessageNew };
    _campaignMessageNew[`${name}`] = val;

    setCampaignMessageNew(_campaignMessageNew);
  };
  const onInputUpdateChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _campaignMessageUpdate = { ...campaignMessageUpdate };
    _campaignMessageUpdate[`${name}`] = val;

    setCampaignMessageUpdate(_campaignMessageUpdate);
  };
  const deleteMessage = (rowData) => {
    setLoading(true);
    campaignIntegration
      .deleteMessage(rowData.title, selectedBots.code)
      .then((data) => {
        getMessages(selectedBots.code);
        toast.current.show({
          severity: "success",
          summary: marketing.success,
          detail: marketing.msgDeleted,
          life: 5000,
        });
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: marketing.error,
          detail: marketing.msgErrorDeleted,
          life: 5000,
        });
      });
  };

  const onUpload = (props) => {
    if (
      props.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      props.files[0].type === "application/vnd.ms-excel"
    ) {
      campaign.fileName = props.files[0].name;
      if (props.files[0].name.indexOf(" ") >= 0) {
        toast.current.show({
          severity: "error",
          summary: marketing.error,
          detail: marketing.spaceError,
        });
      } else {
        setInactivated(false);
        toast.current.show({
          severity: "info",
          summary: marketing.success,
          detail: marketing.fileUpload,
        });
      }
    } else {
      setImageTxt("#IMAGEM{" + props.files[0].name + "}");
      toast.current.show({
        severity: "info",
        summary: marketing.success,
        detail: marketing.fileUpload,
      });
    }
  };

  const validatedTemplate = (rowData) => {
    return (
      <React.Fragment>
        <ToggleButton
          checked={rowData.validated}
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          onLabel={marketing.yes}
          offLabel={marketing.no}
        />
      </React.Fragment>
    );
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label={marketing.btnNew} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={newMessage} />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => updateMessage(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => deleteMessage(rowData)}
        />
      </React.Fragment>
    );
  };
  const onError = (props) => {
    toast.current.show({
      severity: "error",
      summary: marketing.error,
      detail: marketing.errorText,
    });
    setInactivated(true);
  };
  const handleChange = (name, value) => {
    let newCampaign = {
      ...campaign,
      [name]: value,
    };
    setCampaign(newCampaign);
  };
  const chooseOptionsFile = {
    label: marketing.chooseSpread,
    icon: "pi pi-fw pi-plus",
  };
  const chooseOptionsImage = {
    label: marketing.chooseImage,
    icon: "pi pi-fw pi-plus",
  };
  const itemTemplate = (file, props) => {
    setPreviewFile(file);
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{ width: "40%" }}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Tag value={marketing.sending} severity="info" className="p-ml-auto p-px-3 p-py-2" />
      </div>
    );
  };
  const emptyTemplate = () => {
    return previewFile ? (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{ width: "40%" }}>
          <img alt={previewFile.name} role="presentation" src={previewFile.objectURL} width={100} />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {previewFile.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={previewFile.size / 1000 + " kb"} severity="warning" className="p-px-3 p-py-2" />
        <Tag value={"Enviado!"} severity="success" className="p-ml-auto p-px-3 p-py-2" />
      </div>
    ) : (
      <p className="p-m-0">{marketing.imageHere}</p>
    );
  };
  return isAuthenticated ? (
    <div className="page-container">
      <Toast ref={toast} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{marketing.pageTitle}</a>
          </li>
        </ol>
      </nav>
      <Dialog
        visible={showMessageDialog}
        onHide={() => onHide("showMessageDialog")}
        position="top"
        footer={dialogFooter("showMessageDialog")}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
          <i className="pi pi-check-circle" style={{ fontSize: "5rem", color: "var(--green-500)" }}></i>
          <h5>{marketing.successCampaign}</h5>
          <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>{detailMessage}</p>
        </div>
      </Dialog>
      <Dialog
        visible={campaignMessagesDialog}
        modal
        baseZIndex={1400}
        onHide={() => onHide("campaignMessagesDialog")}
        footer={dialogFooter("campaignMessagesDialog")}
        header={marketing.msgCampaign}
        style={{ width: "80vw" }}
      >
        <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
        <DataTable
          ref={dt}
          style={{ width: "70vw" }}
          value={campaignMessages}
          dataKey="id"
          resizableColumns
          emptyMessage={marketing.msgCreate}
          loading={loading}
        >
          <Column field="title" header={marketing.title} sortable></Column>
          <Column field="message" header={marketing.msgContent} sortable></Column>
          <Column header={marketing.aproved} sortable body={validatedTemplate}></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </Dialog>
      <Dialog
        visible={updateMessageDialog}
        modal
        baseZIndex={1450}
        onHide={() => onHide("updateMessageDialog")}
        footer={UpdateDialogFooter}
        header={marketing.msgEdit}
        style={{ width: "70vw" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <span className="p-float-label">
              <InputText
                id="title"
                name="title"
                style={{ display: "flex", width: "100%", marginTop: "20px" }}
                value={campaignMessageUpdate.title}
                disabled
                autoResize
              />
              <label htmlFor="title">{marketing.title}</label>
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className="p-float-label">
              <InputTextarea
                id="message"
                name="message"
                style={{ display: "flex", width: "100%" }}
                value={campaignMessageUpdate.message}
                onChange={(e) => onInputUpdateChange(e, "message")}
                autoResize
              />
              <label htmlFor="message">{marketing.msgContent}</label>
            </span>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        visible={newMessageDialog}
        modal
        baseZIndex={1450}
        onHide={() => onHide("newMessageDialog")}
        footer={NewDialogFooter}
        header={marketing.msgCreation}
        style={{ width: "70vw" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <span className="p-float-label">
              <InputText
                id="title"
                name="title"
                style={{ display: "flex", width: "100%", marginTop: "20px" }}
                value={campaignMessageNew.title}
                onChange={(e) => onInputNewChange(e, "title")}
                autoResize
              />
              <label htmlFor="title">{marketing.title}</label>
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className="p-float-label">
              <InputTextarea
                id="message"
                name="message"
                style={{ display: "flex", width: "100%" }}
                value={campaignMessageNew.message}
                onChange={(e) => onInputNewChange(e, "message")}
                autoResize
              />
              <label htmlFor="message">{marketing.msgContent}</label>
            </span>
          </Grid>
        </Grid>
      </Dialog>
      <div>
        <Paper className={classes.paper} elevation={4}>
          <Box
            sx={{
              marginTop: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography component="h1" variant="h4">
              {marketing.pageTitle}
            </Typography>
            <span className="p-float-label" style={{ marginTop: "20px" }}>
              {!bots.length > 0 ? (
                <Grid container spacing={3} style={{ marginTop: "15px" }}>
                  <Grid item xs={12} md={12}>
                    <Skeleton className="p-mb-2"></Skeleton>
                    <Skeleton width="30rem" className="p-mb-2"></Skeleton>
                    <Skeleton width="15rem" className="p-mb-2"></Skeleton>
                    <Skeleton width="22rem" className="p-mb-2"></Skeleton>
                    <Skeleton width="14rem" className="p-mb-2"></Skeleton>
                    <Skeleton width="10rem" height="4rem"></Skeleton>
                  </Grid>
                </Grid>
              ) : (
                <div>
                  <Dropdown
                    id="bots"
                    name="bots"
                    style={{ display: "flex" }}
                    value={selectedBots}
                    optionLabel="name"
                    onChange={(e) => {
                      setSelectedBots(e.value);
                      processBotSelection(e.value);
                    }}
                    options={bots}
                  />
                  <label htmlFor="bots">{marketing.chooseBot}</label>
                </div>
              )}
            </span>
            {validatingBot && (
              <Grid container spacing={3} style={{ marginTop: "15px" }}>
                <Grid item xs={12} md={12}>
                  <Skeleton className="p-mb-2"></Skeleton>
                  <Skeleton width="30rem" className="p-mb-2"></Skeleton>
                  <Skeleton width="15rem" className="p-mb-2"></Skeleton>
                  <Skeleton width="22rem" className="p-mb-2"></Skeleton>
                  <Skeleton width="14rem" className="p-mb-2"></Skeleton>
                  <Skeleton width="10rem" height="4rem"></Skeleton>
                </Grid>
              </Grid>
            )}

            {selectedBots.length === 0 || validatingBot ? (
              ""
            ) : (
              <form className={classes.form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <FileUpload
                      name="file"
                      url={process.env.REACT_APP_BOT_API + "/files/"}
                      auto
                      onUpload={onUpload}
                      onError={onError}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      maxFileSize={1000000}
                      emptyTemplate={
                        campaign.fileName ? (
                          <p className="p-m-0">{campaign.fileName + " Enviado com sucesso!"}</p>
                        ) : (
                          <p className="p-m-0">{marketing.spreadHere}</p>
                        )
                      }
                      chooseOptions={chooseOptionsFile}
                    />
                    <a href={process.env.REACT_APP_SPREADSHEET_URL + "/leads.xls"} target="_blank">
                      {marketing.download}
                    </a>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <span className="p-float-label">
                      <Calendar
                        id="data"
                        style={{ display: "flex" }}
                        name="date"
                        value={campaign.date}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        dateFormat="dd/mm/yy"
                        mask="99/99/9999"
                        showIcon
                      />
                      <label htmlFor="data">{marketing.shoot}</label>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <span className="p-float-label">
                      <Calendar
                        id="time"
                        style={{ display: "flex" }}
                        value={campaign.time}
                        timeOnly
                        showTime
                        hourFormat="24"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        mask="99:99"
                        showIcon
                        name="time"
                      />
                      <label htmlFor="time">{marketing.time}</label>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <span className="p-float-label">
                      <InputNumber
                        id="interval"
                        style={{ display: "flex" }}
                        name="interval"
                        value={interval}
                        onChange={(e) => setInterval(e.value)}
                      />
                      <Slider
                        name="interval"
                        id="intervalSlider"
                        value={interval}
                        onChange={(e) => setInterval(e.value)}
                        max={999}
                        min={1}
                      />
                      <label htmlFor="interval">{marketing.leadsInterval}</label>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <span className="p-float-label">
                      <Dropdown
                        id="column"
                        name="column"
                        style={{ display: "flex" }}
                        value={campaign.column}
                        optionLabel="name"
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        options={column}
                      />
                      <label htmlFor="column">{marketing.phoneColumn}</label>
                    </span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FileUpload
                      name="file"
                      url={process.env.REACT_APP_BOT_API + "/files/image"}
                      auto
                      onUpload={onUpload}
                      onError={onError}
                      itemTemplate={itemTemplate}
                      emptyTemplate={emptyTemplate}
                      accept="image/jpeg"
                      maxFileSize={1000000}
                      chooseOptions={chooseOptionsImage}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <span className="p-float-label">
                      <Checkbox
                        inputId="check"
                        onChange={(e) => setUseWelcomeIntent(e.checked)}
                        checked={useWelcomeIntent}
                      ></Checkbox>
                      <label htmlFor="check" style={{ marginLeft: "1rem" }}>
                        {marketing.shootCampaign}
                      </label>
                    </span>
                  </Grid>
                  {!useWelcomeIntent && (
                    <Grid item xs={12} md={9}>
                      <span className="p-float-label">
                        <Dropdown
                          id="message"
                          emptyMessage={marketing.msgCreate}
                          name="message"
                          style={{ display: "flex" }}
                          value={campaign.message}
                          onChange={(e) => handleChange(e.target.name, e.target.value)}
                          optionLabel="title"
                          options={validatedMessages}
                        />
                        <label htmlFor="message">{marketing.msgChoose}</label>
                      </span>
                    </Grid>
                  )}
                  {!useWelcomeIntent && (
                    <Grid item justifyContent="flex-end" display="flex" xs={3}>
                      <Button
                        label={marketing.createMsg}
                        className="p-mr-4 p-button-raised p-button-primary"
                        type="button"
                        onClick={CampaignMessagesVisible}
                      />
                    </Grid>
                  )}
                  <Grid item justifyContent="flex-end" display="flex" xs={12}>
                    <Button
                      type="submit"
                      icon="pi pi-save"
                      label={marketing.btnSave}
                      className="p-mr-4 p-button-raised p-button-success "
                      disabled={inactivated || (!campaign.message && !useWelcomeIntent)}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Box>
        </Paper>
      </div>
      <Dialog
        visible={integrationDialog}
        style={{ width: "450px" }}
        header={marketing.integrationIssues}
        modal
        footer={integrationModelDialogFooter}
        onHide={hideIntegrationModelDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
          <span>{messageIntegration}</span>
        </div>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default Campaign;
