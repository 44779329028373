import { Routes, Route } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Pages from "./index"; // Importing all pages from Index.js
import DashboardMenu from "./components/menu/DashboardMenu";
import TopNavBar from "./components/menu/TopNavBar";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";

initFontAwesome();

function App() {
  return (
    <div>
      <Auth0Provider
        domain="lifty.us.auth0.com"
        clientId="nU1KaAubv6eyxr98pFMgr5BR3dcAlLmV"
        redirectUri={window.location.origin}
        audience="https://lifty.us.auth0.com/api/v2/"
        scope="read:current_user update:current_user_metadata"
      >
        <TopNavBar />
        {/* <LoginBar /> */}
        <div className="app-container">
          <DashboardMenu />
          <div>
            <Routes>
              <Route exact path="/" element={<Pages.Home />} />
              <Route path="/reports" element={<Pages.Reports />} />
              <Route path="/mybots" element={<Pages.MyBots />} />
              <Route path="/integrations" element={<Pages.Integrations />} />
              <Route
                path="/BotParamsConfig"
                element={<Pages.BotParamsConfig />}
              />
              <Route path="/calendar" element={<Pages.Calendar />} />
              <Route path="/Help" element={<Pages.Help />} />
              <Route path="/MyTeam" element={<Pages.MyTeamTable />} />
              <Route path="/MyServices" element={<Pages.ServicesTable />} />
              <Route path="/Models" element={<Pages.Models />} />
              <Route path="/CreateBot" element={<Pages.CreateBotLifty />} />
              <Route path="/Campaign" element={<Pages.Campaign />} />
              <Route path="/UserData" element={<Pages.UserData />} />
              <Route path="/MyPlans" element={<Pages.MyPlans />} />
              <Route path="/Reminders" element={<Pages.Reminders />} />
              <Route path="/EventsCreation" element={<Pages.EventsCreation />} />
              <Route path="/Profile" element={<Pages.Profile />} />
            </Routes>
          </div>
        </div>
      </Auth0Provider>
    </div>
  );
}

export default App;
