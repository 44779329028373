import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import MybotsIntegrations from "../components/integration/MybotsIntegrations";
import { useAuth0 } from "@auth0/auth0-react";
import LoginAlert from "./LoginAlert";
import "../components/css/CreateBot.css";
import { labels, myBots, createBotLifty } from "../labels/labels.js";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { CreateBotIntegration } from "../components/integration/CreateBotIntegration";
import { Box, LinearProgress, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { height: "75vh" },
  image: {
    backgroundImage: "url(/background-createbot.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  image_created: {
    backgroundImage: "url(/bot-created.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
  },
}));

const MyBots = () => {
  const createBotIntegration = new CreateBotIntegration();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [shouldLoadData, setShouldLoadData] = useState(true);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const mybotsIntegrations = new MybotsIntegrations();
  const toast = useRef(null);

  const [chatUrl, setChatUrl] = useState("");
  const [botName, setBotName] = useState("");
  const [testDialog, setTestDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [rowBot, setRowBot] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [instanceId, setInstanceId] = useState("");
  const [newBot, setNewBot] = useState(null);

  const [botGpt, setBotGpt] = useState({
    assistantId: '',
    botName: ''
  });

  const [progress, setProgress] = useState(false);

  const handleSave = (e) => {
    let templateBot = {
      botName: botName,
      templateId: selectedTemplate.code,
      instanceId: instanceId,
      tenantId: user.email,
    };

    setProgress(true);
    createBotIntegration
      .saveBot(templateBot)
      .then((json) => {
        showResponseMessage(json);
        if (json.id) {
          setNewBot(json);
          setBotName(json.botName);
          configureBot(json.id);
        } else {
          toast.current.show({
            severity: "error",
            summary: createBotLifty.errorTitle,
            detail: createBotLifty.errorMessage,
            life: 5000,
          });
        }
      })
      .catch((error) => {
        setProgress(false);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
      });
  };

  const handleCreateBotGpt = (e) => {
    setProgress(true);
    createBotIntegration
      .saveBotGpt(botGpt)
      .then((json) => {
        showResponseMessage(json);
        if (!json.id) {
          toast.current.show({
            severity: "error",
            summary: createBotLifty.errorTitle,
            detail: createBotLifty.errorMessage,
            life: 5000,
          });
        }
      })
      .catch((error) => {
        setProgress(false);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
      });
  };

  const showResponseMessage = (json) => {
    setNewBot(json);
    setBotName(json.botName);
    if (json.status && json.status !== 200) {
      toast.current.show({
        severity: "error",
        summary: createBotLifty.errorTitle,
        detail: json.error,
        life: 5000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: createBotLifty.success,
        detail: createBotLifty.botSuccess,
        life: 5000,
      });
    }
  };

  const configureBot = (botId) => {
    createBotIntegration
      .configureBot(botId)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: createBotLifty.success,
          detail: createBotLifty.botCustomSuccess,
          life: 5000,
        });
        setProgress(false);
        setBotName("");
        setSelectedTemplate("");
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
        setProgress(false);
        setBotName("");
        setSelectedTemplate("");
      });
  };

  const handleChangeTemplate = (event) => {
    setSelectedTemplate(event.target.value);
    let template = event.target.value.code;
    createBotIntegration
      .getInstance(template)
      .then((data) => {
        if (data) {
          let highestAvailableInstanceId;
          for (let i = 0; i < data.length; i++) {
            if (data[i].currentAvailableBotSlots > 0) {
              highestAvailableInstanceId = data[i].id;
              break;
            }
          }
          if (highestAvailableInstanceId) {
            setInstanceId(highestAvailableInstanceId);
          } else {
            window.alert(createBotLifty.botMaxAlert);
          }
        } else {
          setInstanceId("");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: createBotLifty.errorTitle,
          detail: createBotLifty.errorMessage,
          life: 5000,
        });
      });
  };

  const [createBotDialog, setCreateBotDialog] = useState(false);

  const [createBotGptDialog, setCreateBotGptDialog] = useState(false);

  const navigate = useNavigate();

  const [expandedRows, setExpandedRows] = useState(null);

  async function getAccessToken() {
    return await getAccessTokenSilently({
      audience: `https://lifty.us.auth0.com/api/v2/`,
      scope: "read:current_user",
    });
  }

  //Fetch Json Data
  const loadInitialData = async () => {
    const tenantId = user.email;
    if (shouldLoadData) {
      createBotIntegration
        .getTemplatesByCreatedBy(tenantId)
        .then((data) => {
          if (data) {
            setTemplateOptions(
              data.map((template) => ({
                name: template.nameTemplate,
                code: template.id,
              }))
            );
          }
        })
        .catch((error) => {
          console.log(error);
          toast.current.show({
            severity: "error",
            summary: createBotLifty.errorTitle,
            detail: createBotLifty.errorMessage,
            life: 5000,
          });
        });
      getAccessToken().then((accessToken) => {
        mybotsIntegrations.getBots(tenantId, accessToken).then((data) => {
          if (data) {
            setShouldLoadData(false);
            setTableData(data);
            setLoading(false);
          }
        });
      });
    }
  };
  const handleChat = (data) => {
    const chatURL = process.env.REACT_APP_CHAT_URL + data.id;
    setChatUrl(chatURL);
    setTestDialog(true);
    setBotName(data.botName);
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <div className="p-fluid p-grid">
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-tags"
              label={myBots.menuAndProducs}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/Models?botId=" + data.id)}
            />
          </div>
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-cog"
              label={myBots.config}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/BotParamsConfig?botId=" + data.id)}
            />
          </div>
          {/* <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-database"
              label="DADOS DE USUÁRIOS"
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => navigate("/UserData?botId=" + data.id)}
            />
          </div> */}
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-whatsapp"
              label={myBots.connections}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() =>
                navigate(
                  "/Integrations?botId=" + data.id + "&botName=" + data.botName
                )
              }
            />
          </div>
          <div className="p-col">
            <Button
              style={{ height: "100%" }}
              icon="pi pi-caret-right"
              label={myBots.tryIt}
              className="p-button-rounded p-button-primary p-mr-2"
              onClick={() => handleChat(data)}
            />
          </div>
        </div>
      </div>
    );
  };
  const confirmDeleteBot = (rowData) => {
    setRowBot(rowData);
    setDeleteDialog(true);
  };
  const deleteBot = () => {
    mybotsIntegrations
      .disableBot(rowBot.id)
      .then((json) => {
        toast.current.show({
          severity: "success",
          summary: myBots.sucess,
          detail: myBots.deleteSuccess,
          life: 3000,
        });
        setDeleteDialog(false);
        setLoading(true);
        setShouldLoadData(true);
        loadInitialData();
      })
      .catch((error) => {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Erro!",
          detail: myBots.deleteError,
          life: 5000,
        });
      });
  };
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-plus"
          label={myBots.createNew}
          className="p-button-success p-mr-2"
          onClick={() => setCreateBotDialog(true)}
        />
        <Button
          icon="pi pi-plus"
          label={myBots.createNewGpt}
          className="p-button-success"
          onClick={() => setCreateBotGptDialog(true)}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    };

    return (
      <React.Fragment>
        {rowData.enabled ? (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger p-mr-2"
            onClick={() => confirmDeleteBot(rowData)}
          />
        ) : (
          <div style={{ color: "red", fontWeight: "bold" }}>
            <span>Desativado em</span>
            {rowData.disabledAt && (
              <div>
                <small>{formatDate(rowData.disabledAt)}</small>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const hidedeleteDialog = () => {
    setDeleteDialog(false);
  };
  const deleteDialogFooter = (
    <React.Fragment>
      <Button
        label={myBots.btnNo}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hidedeleteDialog}
      />
      <Button
        label={myBots.btnYes}
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteBot}
      />
    </React.Fragment>
  );

  //loading logic
  if (isLoading) {
    return (
      <div>
        <br /> {myBots.loading}
      </div>
    );
  } else if (isAuthenticated) {
    loadInitialData();
  }

  //JSX (page)
  return isAuthenticated ? (
    <div className="page-container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">
            <a href="#">{labels.breadCrumbs}</a>
          </li>
        </ol>
      </nav>
      <div>
        <div className="datatable-rowexpansion-demo">
          <Toast ref={toast} />
          {labels.createNew && (
            <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
          )}
          <div className="card">
            <DataTable
              value={tableData}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              responsiveLayout="scroll"
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
              style={{ width: "70vw" }}
              loading={loading}
              emptyMessage={myBots.emptyMsg}
            >
              <Column expander style={{ width: "3em" }} />
              <Column field="botName" header={labels.botName} sortable />
              <Column field="botType" header={labels.botType} sortable />
              <Column field="templateName" header="Template" sortable />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        visible={testDialog}
        position={"bottom-right"}
        modal={false}
        style={{ height: "100%", width: "600px" }}
        contentStyle={{ padding: "0px", overflow: "hidden" }}
        header={botName || botGpt.botName}
        baseZIndex={1300}
        onHide={() => setTestDialog(false)}
      >
        <iframe
          title="myBook"
          src={chatUrl}
          frameBorder="0"
          scrolling="no"
          allowFullScreen={true}
          style={{
            width: "100%",
            height: "100%",
            padding: "0",
            float: "none",
            overflowY: "none",
          }}
        ></iframe>
      </Dialog>
      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={myBots.confirm}
        modal
        footer={deleteDialogFooter}
        onHide={hidedeleteDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {rowBot && (
            <span>
              {myBots.deleteConfirmation} <b>{rowBot.botName}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        baseZIndex={5000}
        visible={createBotDialog}
        style={{ width: "60rem" }}
        header={myBots.createNew}
        onHide={() => setCreateBotDialog(false)}
      >
        <Grid container component="main" className={classes.root} spacing={3}>
          {newBot !== null ? (
            <>
              <Grid item xs={7} className={classes.image_created} />
              <Grid item xs={5}>
                <Box
                  sx={{
                    marginTop: 8,
                    marginLeft: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlineIcon
                    color="success"
                    fontSize="large"
                    style={{ width: "7rem", height: "7rem" }}
                  />
                  <Typography component="h1" variant="h4">
                    Seu bot foi
                  </Typography>
                  <Typography component="h1" variant="h4">
                    criado com sucesso!
                  </Typography>
                  <Button
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Testar meu bot"
                    type="submit"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_CHAT_URL + newBot.id,
                        "_blank"
                      )
                    }
                    className="p-button-primary p-mr-2"
                  />
                  <Button
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Integrar com whatsapp"
                    type="submit"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WHATSAPP_INTEGRATION}?botId=${newBot.id}`,
                        "_blank"
                      )
                    }
                    className="p-button-primary p-mr-2"
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <Grid container spacing={4} style={{ marginTop: "4rem" }}>
                  <Grid item xs={12}>
                    <Dropdown
                      emptyMessage="Nenhum template cadastrado"
                      id="dropdown"
                      style={{ width: "100%" }}
                      value={selectedTemplate}
                      options={templateOptions}
                      optionLabel="name"
                      onChange={handleChangeTemplate}
                      placeholder="Qual o fluxo seu chatbot vai usar?"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <span className="p-float-label">
                      <InputText
                        id="botName"
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                        style={{ width: "100%" }}
                      />
                      <label htmlFor="botName">Nome do cliente</label>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {progress ? (
                      <>
                        <Button
                          style={{ width: "100%", opacity: "1" }}
                          disabled
                          label="Aguarde estamos criando seu bot"
                          className="p-button-primary p-mr-2"
                        />
                        <LinearProgress />
                      </>
                    ) : (
                      <Button
                        style={{ width: "100%" }}
                        icon="pi pi-save"
                        label="Criar"
                        type="submit"
                        onClick={handleSave}
                        className="p-button-primary p-mr-2"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7} className={classes.image} />
            </>
          )}
        </Grid>
      </Dialog>

      <Dialog
        baseZIndex={5000}
        visible={createBotGptDialog}
        style={{ width: "60rem" }}
        header={myBots.createNewGpt}
        onHide={() => setCreateBotGptDialog(false)}
      >
        <Grid container component="main" className={classes.root} spacing={3}>
          {newBot !== null ? (
            <>
              <Grid item xs={7} className={classes.image_created} />
              <Grid item xs={5}>
                <Box
                  sx={{
                    marginTop: 8,
                    marginLeft: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlineIcon
                    color="success"
                    fontSize="large"
                    style={{ width: "7rem", height: "7rem" }}
                  />
                  <Typography component="h1" variant="h4">
                    Seu bot foi
                  </Typography>
                  <Typography component="h1" variant="h4">
                    criado com sucesso!
                  </Typography>
                  <Button
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Testar meu bot"
                    type="submit"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_CHAT_URL + newBot.id,
                        "_blank"
                      )
                    }
                    className="p-button-primary p-mr-2"
                  />
                  <Button
                    style={{ width: "100%", marginTop: "20px" }}
                    label="Integrar com whatsapp"
                    type="submit"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WHATSAPP_INTEGRATION}?botId=${newBot.id}`,
                        "_blank"
                      )
                    }
                    className="p-button-primary p-mr-2"
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={5}>
                <Grid container spacing={4} style={{ marginTop: "4rem" }}>
                  <Grid item xs={12}>
                  <span className="p-float-label">
                      <InputText
                        id="assistantId"
                        value={botGpt.assistantId}
                        onChange={(e) => setBotGpt({...botGpt, assistantId: e.target.value})}
                        style={{ width: "100%" }}
                      />
                      <label htmlFor="assistantId">assistantId</label>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className="p-float-label">
                      <InputText
                        id="botName"
                        value={botGpt.botName}
                        onChange={(e) => setBotGpt({...botGpt, botName: e.target.value})}
                        style={{ width: "100%" }}
                      />
                      <label htmlFor="botName">Nome do cliente</label>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {progress ? (
                      <>
                        <Button
                          style={{ width: "100%", opacity: "1" }}
                          disabled
                          label="Aguarde estamos criando seu bot"
                          className="p-button-primary p-mr-2"
                        />
                        <LinearProgress />
                      </>
                    ) : (
                      <Button
                        style={{ width: "100%" }}
                        icon="pi pi-save"
                        label="Criar"
                        type="submit"
                        onClick={handleCreateBotGpt}
                        className="p-button-primary p-mr-2"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7} className={classes.image} />
            </>
          )}
        </Grid>
      </Dialog>
    </div>
  ) : (
    <LoginAlert />
  );
};
export default MyBots;
