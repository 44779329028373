export class CreateBotIntegration {

    getTemplates() {
        return fetch(process.env.REACT_APP_BOT_API + '/bot-templates')
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }
    getTemplatesByCreatedBy(createdBy) {
        return fetch(process.env.REACT_APP_BOT_API + '/bot-templates/createdby/' + createdBy)
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }

    getInstance(template) {
        return fetch(process.env.REACT_APP_BOT_API + '/bot-template/' + template + '/instance')
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }

    saveBot(templateBot) {
        //NEW EMPLOYEE
        return fetch(process.env.REACT_APP_BOT_API + '/bot', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(templateBot),
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }

    saveBotGpt(templateBot) {
        return fetch(process.env.REACT_APP_BOT_API + '/bot/gpt', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(templateBot),
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });
    }
    configureBot(botId) {
        return fetch(process.env.REACT_APP_BOT_API + '/bot/configure/' + botId, {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .catch(error => {
                throw error;
            });

    }
    getBusiness() {
        return fetch(process.env.REACT_APP_JSON_URL + '/businessLines.json', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then(d => d.data);
    }

}