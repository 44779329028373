export default class ServicesIntegration {

    getServices(tenantId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/service`).then(response => {
            if (response.status == 200) {
                return response.json();
            } else if (response.status == 204) {
                return [];
            }
            else {
                return response.json()
            }
        })
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter lista de serviços.');
            });
    }

    getServicesEmployee(tenantId, serviceId) {
        return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/service/${serviceId}`).then(res => res.json())
            .catch(error => {
                console.log(error);
                window.alert('Erro ao obter lista de funcionarios.');
            });
    }

    saveService(tenantId, service, templateService) {
        //verify if it is editing
        if (service.id) {
            return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/service/${service.id}`, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(templateService),
            })
                .then(response => response.json())
                .catch(error => {
                    throw error;
                });
        } else {
            //NEW EMPLOYEE
            return fetch(`${process.env.REACT_APP_BOT_API}/data/tenant/${tenantId}/service`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(templateService),
            })
                .then(response => response.json())
                .catch(error => {
                    throw error;
                });
        }
    }
}