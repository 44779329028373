import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

//Importing all individual pages
import Home from "./pages/Home";
import MyBots from "./pages/MyBots";
import Reports from "./pages/Reports";
import Integrations from "./pages/Integrations";
import Calendar from "./pages/Calendar";
import Campaign from "./pages/Campaign";
import Help from "./pages/Help";
import MyTeamTable from "./pages/MyTeam";
import ServicesTable from "./pages/MyServices";
import Models from "./pages/Models";
import CreateBotLifty from "./pages/CreateBotLifty";
import BotParamsConfig from "./pages/BotParamsConfig";
import UserProfile from "./components/UserProfile";
import UserData from "./pages/UserData";
import MyPlans from "./pages/MyPlans";
import Reminders from "./pages/Reminders";
import EventsCreation from "./pages/EventsCreation";
import Profile from "./pages/Profile";
import NoMobile from "./NoMobile";



ReactDOM.render(
  <React.StrictMode>
    <Router>
      {window.innerWidth < 800 ? (<NoMobile />) : (<App />)}
    </Router>,
  </React.StrictMode>,
  document.getElementById("root")
);

//Exporting all pages so they can be used on app.js
export {
  Home,
  MyBots,
  Reports,
  Integrations,
  Calendar,
  Help,
  MyTeamTable,
  ServicesTable,
  Models,
  CreateBotLifty,
  BotParamsConfig,
  UserProfile,
  Campaign,
  UserData,
  MyPlans,
  Reminders,
  EventsCreation,
  Profile,
};
